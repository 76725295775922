import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { format } from "date-fns";
import IMAGES from "lib/assets/images";
import AddressFields from "lib/components/formFields/AddressFields/AddressFields";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { setFromInitialProfile } from "lib/redux/navigation";
import { setShouldDirectToMyInfo } from "lib/redux/user";
import { PATHS } from "lib/routing";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  initialState,
  setEpicProfileState,
  setHasFetchedEpicProfile,
  setMyInfoProfileState,
} from "../PatientMaster/ducks";
import {
  selectEpicProfileState,
  selectPMSettingsValue,
} from "../PatientMaster/ducks/selectors";
import { sxStyles } from "./PersonalDetails.styles";
import { PersonalDetailsProps } from "./PersonalDetails.types";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { selectUser } from "lib/redux/user/selectors";

const PersonalDetails = ({
  showUnsyncParticularsWarning = false,
}: PersonalDetailsProps) => {
  const theme = useTheme();
  const classes = sxStyles(theme);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Redux states
  const { disclaimers } = useAppSelector(selectPMSettingsValue);
  const pmState = useAppSelector(selectEpicProfileState);
  const userState = useAppSelector(selectUser);

  // Local states
  const [isMeansTestingClicked, setIsMeansTestingClicked] = useState(false);
  const [isNricLinkClicked, setIsNricLinkClicked] = useState(false);

  const dateOfBirthObj = getDateFromISOString(pmState.DateOfBirth);
  const formattedDateOfBirth =
    dateOfBirthObj && format(dateOfBirthObj, "d MMM yyyy");

  // Click handlers
  const handleMyInfoButtonClick = () => {
    logEventToGoogleAnalytics(EVENTS.CLICK_PROFILE_RETRIEVE_MYINFO_SINGPASS);
    dispatch(setHasFetchedEpicProfile(false));
    dispatch(setEpicProfileState(initialState.epicProfile.value));
    dispatch(setMyInfoProfileState(initialState.myInfoProfile.value));
    dispatch(setFromInitialProfile(location.pathname));
    dispatch(
      setShouldDirectToMyInfo({
        shouldDirectToMyInfo: true,
        cancellationPath: location.pathname,
      }),
    );

    navigate(PATHS.MY_INFOPROFILE.path);
  };

  const handleMeansTestingLinkClick = () => {
    setIsMeansTestingClicked(!isMeansTestingClicked);
  };

  const handleNricLinkClick = () => {
    setIsNricLinkClicked(!isNricLinkClicked);
  };

  return (
    <Box sx={classes.uneditableFieldsContainer}>
      <Typography sx={classes.introText}>
        {disclaimers.personalDetails.description}
      </Typography>

      {!userState.isMismatched ? (
        <>
          {showUnsyncParticularsWarning && (
            <SharpNoticePanel bgColor="extremeWarn">
              {disclaimers.unsyncParticulars.description}
            </SharpNoticePanel>
          )}

          <Box sx={classes.myInfoButton}>
            <IconButton onClick={handleMyInfoButtonClick}>
              <img src={IMAGES.pm.MyInfo} alt="MyInfo" />
            </IconButton>
          </Box>
        </>
      ) : null}

      <Box sx={classes.fieldsContainer}>
        <Box>
          <Typography sx={classes.formLabel}>NRIC / FIN</Typography>
          <Typography sx={classes.formValue}>
            {pmState.Nric !== "" && pmState.Nric ? pmState.Nric : "-"}
          </Typography>
          <Typography sx={classes.nricLink} onClick={handleNricLinkClick}>
            Did you change your NRIC / FIN?
          </Typography>
        </Box>

        <Box sx={classes.formContainer}>
          <Typography sx={classes.formLabel}>Name</Typography>
          <Typography sx={classes.formValue}>
            {pmState.Name !== "" && pmState.Name ? pmState.Name : "-"}
          </Typography>
        </Box>

        <Box sx={classes.formContainer}>
          <Typography sx={classes.formLabel}>Sex</Typography>
          <Typography sx={classes.formValue}>
            {pmState.Sex !== "" && pmState.Sex ? pmState.Sex : "-"}
          </Typography>
        </Box>

        <Box sx={classes.formContainer}>
          <Typography sx={classes.formLabel}>Date of Birth</Typography>
          <Typography sx={classes.formValue}>
            {formattedDateOfBirth ?? "-"}
          </Typography>
        </Box>

        <Box sx={classes.formContainer}>
          <Typography sx={classes.formLabel}>Nationality</Typography>
          <Typography sx={classes.formValue}>
            {pmState.Nationality !== "" && pmState.Nationality
              ? pmState.Nationality
              : "-"}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ px: 1.25 }}>
        <Typography sx={classes.subsubtitle}>Registered Address</Typography>
        <Box mt={0.5}>
          <Typography sx={classes.caption}>
            This address is used for{" "}
            <Typography
              component="span"
              sx={classes.link}
              onClick={handleMeansTestingLinkClick}
            >
              Means Testing
            </Typography>{" "}
            purposes.
          </Typography>
        </Box>

        <Box sx={{ mt: -1, mb: 2 }}>
          <AddressFields
            postalCode={
              pmState.RegisteredPostalCode !== "" &&
              pmState.RegisteredPostalCode
                ? pmState.RegisteredPostalCode
                : null
            }
            block={
              pmState.RegisteredBlockNumber !== "" &&
              pmState.RegisteredBlockNumber
                ? pmState.RegisteredBlockNumber
                : null
            }
            unitNo={
              pmState.RegisteredUnitNumber !== "" &&
              pmState.RegisteredUnitNumber
                ? pmState.RegisteredUnitNumber
                : null
            }
            street={
              pmState.RegisteredStreetName !== "" &&
              pmState.RegisteredStreetName
                ? pmState.RegisteredStreetName
                : null
            }
            disabled={true}
            errorPostalCode={false}
            onChangePostalCode={() => {}}
            onChangeBlock={() => {}}
            onChangeUnitNo={() => {}}
            onChangeStreet={() => {}}
            showRequiredText={false}
          />
        </Box>
      </Box>

      <ConfirmationModal
        title={disclaimers.meansTesting.title}
        open={isMeansTestingClicked}
        onClose={() => {}}
        body={MapRawStringToReactElement(
          disclaimers.meansTesting.description ?? "",
        )}
        hideCancelButton={true}
        nextButtonText="OK"
        onClickNext={handleMeansTestingLinkClick}
      />

      <ConfirmationModal
        title={disclaimers.imptToNote.title}
        open={isNricLinkClicked}
        onClose={() => {}}
        body={MapRawStringToReactElement(
          disclaimers.imptToNote.description ?? "",
        )}
        hideCancelButton={true}
        nextButtonText="OK"
        onClickNext={handleNricLinkClick}
      />
    </Box>
  );
};

export default PersonalDetails;
