import { Box, Button, Typography } from "@mui/material";
import { IconLinkProps } from "./IconLink.types";
import { sxStyles } from "./IconLink.styles";
import IMAGES from "lib/assets/images";

const IconLink = ({
  title,
  email,
  icon = "externalLink",
  iconPosition = "start",
  onClick,
  disabled = false,
  required = false,
}: IconLinkProps) => {
  const classes = sxStyles(disabled);

  const iconSrc =
    icon === "externalLink" ? (
      <Box component={"img"} src={IMAGES.general.ExternalLinkIcon} />
    ) : icon === "addCircleOutline" ? (
      <Box component={"img"} src={IMAGES.general.AddIcon} />
    ) : icon === "arrowRightOutline" ? (
      <Box component={"img"} src={IMAGES.general.ArrowRightIcon} />
    ) : undefined;

  const iconSrcDisabled =
    icon === "externalLink" ? (
      <Box component={"img"} src={IMAGES.general.ExternalLinkIcon} />
    ) : icon === "addCircleOutline" ? (
      <Box component={"img"} src={IMAGES.general.AddIconDisabled} />
    ) : icon === "arrowRightOutline" ? (
      <Box component={"img"} src={IMAGES.general.ArrowRightIconDisabled} />
    ) : undefined;

  const handleEmail = () => {
    // eslint-disable-next-line no-restricted-globals
    location.href = `mailto:${email}`;
  };

  return (
    <>
      <Button
        {...{
          variant: "text",
          sx: classes.button,
          ...(iconPosition === "start"
            ? { startIcon: disabled ? iconSrcDisabled : iconSrc }
            : { endIcon: disabled ? iconSrcDisabled : iconSrc }),
          disabled: disabled,
        }}
        onClick={() => {
          if (email) {
            handleEmail();
          }

          if (onClick) {
            onClick();
          }
        }}
      >
        {title}
      </Button>
      {required && (
        <Typography sx={classes.requiredText}>{"*Required"}</Typography>
      )}
    </>
  );
};

export default IconLink;
